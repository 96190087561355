.button {
    padding: 20px 40px;
    background-color: #c2b0ff;
    font-size: 28px;
    text-transform: uppercase;
    letter-spacing: 0.3em;
    box-shadow: inset 0 0 3vh rgba(0, 0, 0, 0.6);
    overflow: hidden;
    position: relative;
}

.button::before,
.button::after {
    --size: 150px;
    content: '';
    position: absolute;
    left: 50%;
    bottom: 30%;
    width: var(--size);
    height: var(--size);
    transform: translate(-50%, 0);
    background: linear-gradient(
        45deg,
        rgba(107, 0, 0, 0.8),
        rgba(0, 58, 130, 0.5)
    );
    border-radius: 40%;
    transition: all 1s;
}

.button::before {
    animation: rotate 5s linear infinite;
    left: 60%;
    bottom: 60%;
}

.button::after {
    background: linear-gradient(
        45deg,
        rgba(0, 215, 39, 0.7),
        rgba(135, 30, 221, 0.593)
    );
    animation: rotate 6s linear infinite;
    left: 40%;
    bottom: 68%;
}

.button:hover::before,
.button:hover::after {
    bottom: 150%;
}

.text {
    color: white;
    position: relative;
}

@keyframes rotate {
    0% {
        transform: translate(-50%, 0) rotate(0deg);
    }
    100% {
        transform: translate(-50%, 0) rotate(360deg);
    }
}